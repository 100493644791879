import { RouterModule } from '@angular/router';
import { SOCIAL_LINKS } from './../../constants/constant';
import { Component, inject } from '@angular/core';
import { AbsolutePathPipe } from 'src/app/pipes/absolutePath/absolute-path.pipe';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule,AbsolutePathPipe],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
public socialLinks=SOCIAL_LINKS
private readonly common = inject(CommonService)
comingSoon(){
  this.common.comingSoon()
}
}
