import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _storage: StorageService) {}

  authorization(basic = false) {
    const auth: any = {
    'Authorization': 'Basic ' + btoa(`${'memories'}:${'memories@123'}`),
    "api_key": environment.API_KEY,
    'platform':"" + this._storage.deviceDetail(3),
    'accept-language': "en",
    };
    return auth;
  }
}
