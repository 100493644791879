import { Routes } from '@angular/router';
import { ABOUT_US, FAQ, HOME } from './constants/routes';

export const ROUTES: Routes = [
  { path: "", redirectTo: HOME.path, pathMatch: "full" },
  {
    path: HOME.path,
    loadComponent: () => import("./features/home/home.component").then((c) => c.HomeComponent),
  },
  {
    path: ABOUT_US.path,
    loadComponent: () =>
      import("./features/about-us/about-us.component").then(
        (m) =>m.AboutUsComponent 
      ),
  },
  {
    path: FAQ.path,
    loadComponent: () => import("./features/faq/faq.component").then((c) => c.FaqComponent),
  },
  {
    path: "**",
    loadComponent: () => import("./features/not-found/not-found.component").then((c) => c.NotFoundComponent),
  },
];

