
export const CONTENT_TYPES = {
  ABOUT_US:"ABOUT_US",
  LEGAL_USER_AGREEMENTS:"LEGAL_USER_AGREEMENTS",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  TERMS_CONDITIONS: "TERMS_OF_USE",
  BILLING_TERMS: "BILLING_TERMS",
  HELP_AND_SUPPORT: "SUPPORT",
  FAQS: "FAQ",
};

export const LOGIN_ENUM = {
  EMAIL:"EMAIL",
  MOBILE:"MOBILE"
}
export const BUTTON_TYPE_ENUM = {
  MEMORY:"Memory"
}
export const NOT_FOUND=undefined
export const EMAIL='inquiries@unspokenmemories.com';
export const SOCIAL_LINKS ={
  TWITTER:'https://x.com/Unspokenme20247?t=-8KpuDGSXNM5dFuN8EpURQ&s=09',
  INSTAGRAM:'https://www.instagram.com/unspokenmemoriesapp/profilecard/?igsh=ajgzOWYyMDBtYnRr ',
  YOUTUBE:'http://www.youtube.com/@UnSpokenMemories-2024 ',
  LINKEDIN:'https://www.linkedin.com/login'

}


