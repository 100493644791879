import { Iroute } from './interface';

/**
 * @ABS === ACCOUNT
 */
export const HOME: Iroute = {
  path: 'home',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const VERIFY_OTP: Iroute = {
  path: 'verify-otp',
  get fullUrl(): string {
    return `${HOME.fullUrl}/${this.path}`;
  },
};
export const FAQ: Iroute = {
  path: 'faq',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const ABOUT_US: Iroute = {
  path: 'about-us',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};