import { CommonModule } from '@angular/common';
import {  Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import {  RouterModule } from '@angular/router';
import { NOT_FOUND } from 'src/app/constants/constant';
import { AbsolutePathPipe } from 'src/app/pipes/absolutePath/absolute-path.pipe';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, AbsolutePathPipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{
  id:any
  isSidebarOpen = false;
  public notFound=NOT_FOUND
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }
  ngOnInit(): void {
    this.id = atob(sessionStorage.getItem('memoryId'));
  }
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    if (this.isSidebarOpen) {
      this.renderer.addClass(document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(document.body, 'no-scroll');
    }
  }

  closeSidebar() {
    this.isSidebarOpen = false;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.isSidebarOpen) {
      this.isSidebarOpen = false; // Close sidebar if clicked outside
    }
  }
}
