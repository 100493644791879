import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  profileDetail: any = null;
  campaignGeneralSettings:any
  businessPinGeneralSettings:any

  constructor() {
    
  }

  deviceDetail(info?: number): string {
    /*---1=device_token, 2=deviceId, 3=platform---*/
    switch (info) {
      case 1:
        let deviceToken = this.attachDeviceToken();
        return deviceToken;
      case 2:
        let deviceId = this.randomDeviceId();
        return deviceId;
      case 3:
        return '1';
      default:
        return this.getTimeZone().toString();
    }
  }

  attachDeviceToken() {
    return (Date.now() + Math.floor(Math.random() * 1000000) + 1).toString();
  }

  randomDeviceId() {
    return window.navigator.userAgent.replace(/\D+/g, '');
  }

  getTimeZone() {
    let date = new Date();
    let offset = date.getTimezoneOffset() * -1;
    return offset * 60 * 1000;
  }
}
