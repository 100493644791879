<app-loader></app-loader>
<app-toast-notification></app-toast-notification>
<app-header></app-header>
<main class="main_wrapper">
    @if (loader) {
    <app-data-loader></app-data-loader>
    }@else {
    <router-outlet></router-outlet>
    }
</main>
<app-footer></app-footer>