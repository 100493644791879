import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NOT_FOUND } from 'src/app/constants/constant';
import { AbsolutePathPipe } from 'src/app/pipes/absolutePath/absolute-path.pipe';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, AbsolutePathPipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent{
  id:any
  isSidebarOpen = false;
  public notFound=NOT_FOUND
  constructor(private elementRef: ElementRef, private renderer: Renderer2,private _actRoute:ActivatedRoute,private _storageService:StorageService) {
    // this.id=this._actRoute.snapshot.queryParams['memoryId']
this.id = this._storageService.getMemoryId();
    console.log(this.id);
    
    // if(localStorage.getItem('memoryId') !=null){
    //   this.id = atob(localStorage.getItem('memoryId'));
    // }
  }
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    if (this.isSidebarOpen) {
      this.renderer.addClass(document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(document.body, 'no-scroll');
    }
  }

  closeSidebar() {
    this.isSidebarOpen = false;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.isSidebarOpen) {
      this.isSidebarOpen = false; // Close sidebar if clicked outside
    }
  }
}
