import { AfterViewInit, Component, OnDestroy} from "@angular/core";
import { CommonModule, ViewportScroller } from "@angular/common";
import { ActivatedRoute, Event, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { ToastNotificationComponent } from "./components/toast-notification/toast-notification.component";
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { DataLoaderComponent } from "./components/data-loader/data-loader.component";
import { LoaderComponent } from "./components/loader/loader.component";

registerLocaleData(localeDa, 'da-DK');
@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ToastNotificationComponent,
    HeaderComponent,
    FooterComponent,
    DataLoaderComponent,
    LoaderComponent
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit,OnDestroy {
  title = "Unspoken memories";
  public loader :boolean = true;
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
  ) {

  }
  memoryId: string | null = null;
  ngAfterViewInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.loader = false;
          const element = document.getElementById("main-content");
          this.memoryId = this.route.snapshot.queryParams['memoryId']
          if(this.memoryId){
            sessionStorage.setItem(('memoryId'),btoa(this.memoryId))
          }
          if (element) {
            element.scrollTo(0, 0);
          } else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }, 500);
      }
    });
  }
  ngOnDestroy(): void {
    localStorage.clear();
  }
}
